.toggle-button {
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 5px;
    font-weight: bold;
    font-size: 1em;
  }
  
  .toggle-button svg {
    margin-right: 5px;
  }
  
  .toggle-content {
    margin-top: 10px;
    border-left: 2px solid #ccc;
    padding-left: 10px;
  }