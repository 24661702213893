.boardContainer {
    height: var(--half-vh);
    width: var(--half-vh); 
    place-items: center;
}

.exampleWrapper {
    display:flex;
    flex-direction: column; /* Stack items vertically on small screens */
}

p {
    margin-top: 0px;
}