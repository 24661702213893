.table-container {
    overflow-y: auto; /* Enable vertical scrolling */
    height: 45vh;
    width: 100%;
}

table {
    border-collapse: collapse;
    width: 100%;
}

td {
    border: 1px solid black;
    padding: 10px;
}

/* Second and Third Columns */
td:nth-child(n+2) {
    width: 30%; /* Width of the second and third columns */
}

.move-column {
    width: 10%;
}

/* Further alternating row colors for the first column */
tr:nth-child(odd)  {
    background-color: #e9e9e9;
}

tr:nth-child(even) {
    background-color: #c6c6c6;
    /* Darker blue for even rows in the first column */
}

.highlighted-cell {
    background-color: rgb(155, 148, 228) !important; /* Example highlight color */
}
