.wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  background-color: "rgba(231, 231, 231, 0.69)";
  overflow: auto;
  min-height: min-content;
}

.header {
  box-sizing: border-box;
  flex: 1;
  justify-content: center;
  align-items: center;
  margin-top: 2vh;
  min-height: calc(max(8vh, 80px));
}

h1 {
  text-align: center;
  text-decoration: none;
  color: black;
  font-size: 1.75em;
}

.bodyContainer {
  flex: 1;
  display: flex;
  justify-content: center; 
  align-items: flex-start;
  min-height: 87vh;
}

.horizontal-list {
  list-style-type: none; 
  display: flex;  
  padding: 0;
  justify-content: center;
  text-align: center;
}

.horizontal-list li {
  padding: 0px 15px;
}

.noStyleLink {
  text-decoration: none;
  color: grey;
}

.homeLink {
  text-decoration: none;
  color: black;
  font-size: 1.5em;
}

.homeLink:hover {
  text-decoration: none;
}

.textBox {
  height: 20vh;
  width: calc(max(20vw, 150px));
}

.moveButton {
  height: 30px;
  margin-right: 8px;
  background-color: #727272;
  color: white;
  border: none; /* Remove default border */
  border-radius: 4px; /* Optional: Add rounded corners */
  min-width: 60px;
  padding-left: 20px;
  padding-right: 20px;
  position: relative;
  margin-bottom: 10px;
}

.moveButton:hover {
  background-color: #414141; /* Darken background color on hover */
}

.statsBox {
  display: flex;
  margin-top: 5px;
}

.statsBox > * {
  margin-right: 30px;
}

.statsBox > *:last-child {
  margin-right: 0;
}

.optionsTitle {
  font-size: 2em;
  font-weight: bold;
  padding-bottom: 20px;
}

.navigationOptions {
  padding-top: 10px;
  padding-bottom: 10px;
}