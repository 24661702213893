.body {
    display: flex;
    width: 80vw;
    margin-top: 5vh;
    margin-bottom: 5vh;
    margin-left: 5vh;
    margin-right: 5vh;
}

.outerBoardWrapper {
    /* display: grid; */
    place-items: end;
    text-align: center;
    flex: 1;
    margin-right: 0px;
    box-sizing: border-box;
}

.innerBoardWrapper {
    display:flex;
    place-items: center;
    flex-direction: column; /* Stack items vertically on small screens */
}

.options {
    flex: 0.5;
    place-items: center;
    box-sizing: border-box;
    float: left;
    overflow-y: auto;
}

/* Media query for mobile view */
@media (max-width: 768px) {
    .body {
        flex-direction: column; /* Stack items vertically on small screens */
    }

    .innerBoardWrapper, .outerBoardWrapper, .options {
        flex: 1; /* Each div takes up full width */
        place-items: center;
        padding-right: 0;
        text-align: center;
        padding-bottom: 20px;
        margin-right: 0;

    }
}

.boardContainer {
    height: var(--half-vh);
    width: var(--half-vh); 
}
