.aboutPage {
    display: flex;
    justify-content: start; /* Center horizontally */
    align-items: top; /* Center vertically */
    flex-direction: column;
    max-width: 70vw;
}

h4 {
    margin-top: 5px;
    margin-bottom: 5px;
}

.collapsible {
    border: 1px solid #ccc;
    margin-bottom: 20px;
  }
  
  .collapsible-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: #f0f0f0;
    cursor: pointer;
  }
  
  .collapse-toggle {
    background: none;
    border: none;
    cursor: pointer;
    outline: none;
  }
  
  .collapsible-content {
    display: none; /* Hide content by default */
    padding: 10px;
  }
  
  .collapsible.active .collapsible-content {
    display: block; /* Show content when .active class is applied */
  }

  .trailer {
    flex: 1;
    text-align: center;
    color:grey;
    font-size: 1em;
    min-height: 3vh;
  }

  ul {
    margin-top: 0;
    margin-bottom: 0;
  }
